window.$ = window.jQuery = require('jquery/dist/jquery')
window.Vue = require('vue/dist/vue')
window.Tether = require('tether')
require('bootstrap')

var md5 = require('blueimp-md5/js/md5.js')

$.get('/api/logged-in', function (data) {
  if (data.loggedIn) {
    document.body.className += ' logged-in'
    $('#logout-link').removeClass('sr-only')
    document.getElementById('avatar').innerHTML = data.first_name[0] + data.last_name[0]

    if (data.roles.general === 'Admin') {
      document.body.className += ' is-admin'
    } else if (data.roles.general === 'Staff') {
      document.body.className += ' is-staff'
    } else if (data.roles.general === 'Approver') {
      document.body.className += ' is-approver'
    } else if (data.roles.general === 'Requester') {
      document.body.className += ' is-requester'
    }
    if (data.masqueraded) {
      document.body.className += ' masqueraded-user'
    }
    window.userEmail = data.email
    window.userFullName = data.first_name + ' ' + data.last_name
    window.userRole = data.roles.general
    window.userCompanyId = data.company_id
    window.userMods = data.modules
    window.userExpenseAccounts = data.roles.expenseAccounts

    var headerNav = document.querySelector('#header-navs')
    for (let i = 0; i < window.userMods.length; i++) {
      var modInfo = window.userMods[i];

      if (modInfo.name === 'check-requests') {
        document.querySelector('.menu-check-requests').style.display = 'block'
      }

      var divLi = document.createElement('li')
      divLi.classList = ['nav-item']
      var navA = document.createElement('a')
      navA.href = '/' + modInfo.name

      // Set the active nav-link
      if (window.location.href.indexOf(navA.href) === 0) {
        navA.classList = ['nav-link nav-link__active']
      } else {
        navA.classList = ['nav-link']
      }

      navA.innerHTML = modInfo.title
      divLi.appendChild(navA)
      headerNav.appendChild(divLi)
    }
    if (['Employee', 'Reviewer'].indexOf(data.roles.expenseReports) !== -1) {
      document.querySelector('.menu-expense-reports').style.display = 'block'

      var divLi = document.createElement('li')
      divLi.classList = ['nav-item']
      var navA = document.createElement('a')
      navA.href = '/expense-reports'
      // Set the active nav-link
      if (window.location.href.indexOf(navA.href) === 0) {
        navA.classList = ['nav-link nav-link__active']
      } else {
        navA.classList = ['nav-link']
      }
      navA.innerHTML = 'Expense Reports'
      divLi.appendChild(navA)
      headerNav.appendChild(divLi)
    }
  } else {
    $('#login-link').removeClass('sr-only')
    if (window.location.pathname !== '/install/' && window.location.pathname !== '/login/') {
      window.location.href = '/login/'
    }
  }

  window.loginStatus = true
  window.dispatchEvent(new Event('loginStatus'))
  document.body.style.display = 'block'
})

if (!window.location.pathname.match(/\/install\/?/)) {
  $.get('/api/install/check', function (data) {
    if (!data.installed) {
      window.location.pathname = '/install'
    }
  })
}

var date = new Date()
var dateElement = document.getElementById('date')
if (dateElement) {
  dateElement.innerHTML = (date.getMonth() + 1) + '/' + date.getDate() + '/' + date.getFullYear()
}

if (document.getElementById('form-validate')) {
  window.toastr = new Vue({
    el: '#form-validate',
    data: {
      messages: [],
      closeOne: function (id) {
        this.messages = this.messages.filter(function (msg) {
          return msg.id !== id
        })
      },
      closeAll: function () {
        this.messages = []
      }
    }
  })
}
